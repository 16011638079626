import { weekday, time } from "../../helpers";
import { enumEspecialities } from "../../utils/appointmentFormatter";
import { API, AppointmentsURLs } from "../../api";
var FormData = require("form-data");

const state = {
  personalInfo: {
    dependent: "",
    zipcode: "",
    state: "",
    city: "",
    district: "",
  },

  speciality: "",

  schedule: {
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    },
    times: {
      morning: false,
      afternoon: false,
      evening: false,
    },
    dayTime: "",
  },
};

const enumBestHour = {
  Tarde: "t",
  Manhã: "m",
  Noite: "n",
  "Qualquer Período": "q",
};

const mutations = {
  updateDependent(state, dependent) {
    state.personalInfo.dependent = dependent;
  },

  updateZipcode(state, zipcode) {
    state.personalInfo.zipcode = zipcode;
  },

  updateState(state, uf) {
    state.personalInfo.state = uf;
  },

  updateCity(state, city) {
    state.personalInfo.city = city;
  },

  updateDistrict(state, district) {
    state.personalInfo.district = district;
  },

  updateSpeciality(state, speciality) {
    state.speciality = speciality;
  },

  updateScheduleDay(state, { day, selected }) {
    state.schedule.dayTime = "";
    state.schedule.days[day] = selected;
  },

  updateScheduleTime(state, { time, selected }) {
    state.schedule.dayTime = "";
    state.schedule.times[time] = selected;
  },

  updateScheduleDayTime(state, dayTime) {
    state.schedule.dayTime = dayTime;
  },

  updateFile(state, { files }) {
    state.files = files;
  },

  resetState() {
    state.schedule = {
      days: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      },
      times: {
        morning: false,
        afternoon: false,
        evening: false,
      },
      dayTime: "",
    };
    state.personalInfo = {
      dependent: "",
      zipcode: "",
      state: "",
      city: "",
      district: "",
    };
    state.speciality = "";
  },
};

const getters = {
  personalInfo: (state) => state.personalInfo,

  addressText: (state) => {
    return (
      state.personalInfo.district +
      " - " +
      state.personalInfo.city +
      " - " +
      state.personalInfo.state
    );
  },

  speciality: (state) => state.speciality,

  schedule: (state) => state.schedule,

  file: (state) => state.files[0],

  scheduledDayTime: (state) => state.schedule.dayTime,

  scheduledTimes: (state) => {
    var times = "";
    var prefix = "";
    for (const [aTime, active] of Object.entries(state.schedule.times)) {
      if (active) {
        times += prefix + time(aTime);
        prefix = ", ";
      }
    }
    return times;
  },

  scheduledDays: (state) => {
    var times = "";
    var prefix = "";
    for (const [day, active] of Object.entries(state.schedule.days)) {
      if (active) {
        times += prefix + weekday(day);
        prefix = ", ";
      }
    }
    return times;
  },

  scheduledDaysJoined: (state) => {
    const weekdays = [];
    for (const [key, value] of Object.entries(state.schedule.days)) {
      if (value) {
        weekdays.push(weekday(key));
      }
    }
    return weekdays.join(",");
  },

  scheduledTimesJoined: (state) => {
    const times = [];
    for (const [key, value] of Object.entries(state.schedule.times)) {
      if (value) {
        times.push(time(key));
      }
    }
    return times.join(",");
  },

  hasFile: (state) => state.files.length > 0,

  fileName: (state) => (state.files.length > 0 ? state.files[0].name : ""),

  fileObject: (state) => state.files[0],
  files: (state) => state.files,
};

const actions = {
  loadSpecialities() {
    return API.get(AppointmentsURLs.SPECIALITIES);
  },

  listScheduleExam: ({ rootGetters }) => {
    const user = rootGetters["account/loggedUser"];

    return API.get(AppointmentsURLs.LIST_SCHEDULE_EXAM(user.cardNumber), {
      headers: {
        Authorization:
          "Bearer ff773d5746e332dfd88e33aee442eac13332c02893ec71c6aec28becb48de7d9",
      },
    });
  },

  createScheduleExam: ({ rootGetters, getters }) => {
    const user = rootGetters["account/loggedUser"];
    const personalInfo = rootGetters["appointments/personalInfo"];
    const speciality = rootGetters["appointments/speciality"];

    const description = `Dias: ${getters.scheduledDaysJoined} | Horário: ${getters.scheduledTimesJoined}`;

    const userDependents = rootGetters["dependents/dependents"];

    const currentDependent = userDependents.find(
      (d) => d.NOME_DEPENDENTE === personalInfo.dependent
    );

    var data = new FormData();
    data.append(
      "patient_name",
      currentDependent ? currentDependent.NOME_DEPENDENTE : user.name
    );
    data.append("cpf", user.cpf);

    data.append(
      "patient_cpf",
      currentDependent ? currentDependent.CPF_DEPENDENTE : user.cpf
    );

    data.append(
      "patient_birthdate",
      currentDependent ? currentDependent.DATA_NASCIMENTO : user.birthDate
    );
    data.append("specialty", speciality);
    data.append("uf", personalInfo.state);
    data.append("city", personalInfo.city);
    data.append("zone", personalInfo.district || "bairro");
    data.append("best_days", getters.scheduledDaysJoined);
    data.append("best_hour", enumBestHour[getters.scheduledTimesJoined] || "q");
    data.append("card_number", user.cardNumber);
    data.append("card_owner", user.nam);
    data.append("product_name", "PAGBANK SAUDE - PLANO FAMILIAR");
    data.append("onix_code", rootGetters["account/onixCode"]);
    data.append("email", user.email);
    data.append("telephone_1", user.phone);
    if (rootGetters["appointments/hasFile"]) {
      rootGetters["appointments/files"].forEach((file, i) =>
        data.append(`attachment[${i}]`, file)
      );
    }
    // Esse campo é obrigatório. Deve haver um campo para selecionar o tipo de exame
    data.append("description", description);
    data.append("type", "CO");
    data.append("exam_group", "NI");

    return API.post(AppointmentsURLs.CREATE_SCHEDULE_EXAM, data, {
      headers: {
        "content-type": "multipart/form-data",
        "Transaction-Key": "fL9Xyabvcy7xf6qeabs28bmcDJUo4ciF",
      },
    });
  },

  listScheduleAppointment: ({ rootGetters }) => {
    const user = rootGetters["account/loggedUser"];

    return API.get(AppointmentsURLs.LIST_SCHEDULE(user.cardNumber), {
      headers: {
        Authorization:
          "Bearer ff773d5746e332dfd88e33aee442eac13332c02893ec71c6aec28becb48de7d9",
      },
    });
  },

  createSchedule: ({ rootGetters, getters }, isVideo) => {
    const user = rootGetters["account/loggedUser"];
    const personalInfo = rootGetters["appointments/personalInfo"];
    const speciality = rootGetters["appointments/speciality"];

    const userDependents = rootGetters["dependents/dependents"];

    const currentDependent = userDependents.find(
      (d) => d.NOME_DEPENDENTE === personalInfo.dependent
    );

    let especialityFinal = enumEspecialities.find(
      (element) => element.tag.toUpperCase() === speciality.toUpperCase()
    );

    especialityFinal = especialityFinal
      ? especialityFinal
      : { tag: speciality, name: speciality };

    const description = `${isVideo ? "Online" : ""}${
      especialityFinal.tag === "Cirurgia Plástica"
        ? " - Procedimento: Cirurgia Plástica"
        : ""
    } - Dias: ${
      getters.scheduledDayTime ? "Hoje" : getters.scheduledDaysJoined
    } - Horário: ${
      getters.scheduledDayTime
        ? getters.scheduledDayTime
        : getters.scheduledTimesJoined
    }`;

    const bestDays = getters.scheduledDayTime
      ? getters.scheduledDayTime
      : getters.scheduledDaysJoined;

    const data = {
      patient_name: currentDependent
        ? currentDependent.NOME_DEPENDENTE
        : user.name,
      cpf: user.cpf,
      patient_cpf: currentDependent
        ? currentDependent.CPF_DEPENDENTE
        : user.cpf,
      description: description,
      patient_birthdate: currentDependent
        ? currentDependent.DATA_NASCIMENTO
        : user.birthDate,
      specialty: especialityFinal.name,
      uf: personalInfo.state || "sp",
      city: personalInfo.city || "sao paulo",
      zone: personalInfo.district || "bairro",
      best_days: bestDays,
      best_hour: enumBestHour[getters.scheduledTimesJoined] || "q",
      card_number: user.cardNumber,
      card_owner: user.name,
      product_name: "PAGBANK SAUDE - PLANO FAMILIAR",
      onix_code: rootGetters["account/onixCode"],
      email: user.email,
      telephone_1: user.phone,
      type: "CO",
      attachment: null,
    };

    return API.post(AppointmentsURLs.CREATE_SCHEDULE, data, {
      headers: {
        "Transaction-Key": "fL9Xyabvcy7xf6qeabs28bmcDJUo4ciF",
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
